const state = {
  loaded: false,

  roles: [],

  roleDetails: {},

  /**
   * Remaining time on idToken in seconds
   */
  remainingTime: null,

  /**
   * Remaining time on a fresh idToken.
   */
  initialRemainingTime: null,
  /**
   * Load userSettings initially from local storage.
   */
  userSettings: JSON.parse(localStorage.getItem('userSettings'))
};

const getters = {
  getFeatureAccess: (state, getters, rootState, rootGetters) => {
    return rootGetters['tenant/getFeatureAccess'];
  },
  securityCheck:
    (state, getters) =>
    ({ attribute }) => {
      const features = getters.getFeatureAccess;
      let accepted = features[attribute] || [];

      if (!Array.isArray(accepted) || accepted.length === 0) {
        return false;
      }

      return state.roles.includes('admin') || accepted.some(role => state.roles.includes(role));
    },

  rolesAreLoaded: state => !!state.loaded,

  hasRole:
    state =>
    ({ role }) =>
      state.roles.includes(role) || state.roles.includes('admin'),

  /**
   * A user is a guest when a user has no particular rights at all, or only the role "guest".
   */
  isGuest: state => state.roles.length === 0 || (state.roles.length === 1 && state.roles[0] === 'guest'),

  roleDetails: state => state.roleDetails,
  canManageRequest: (state, getters) => {
    const isAmsterdam = process.env.VUE_APP_TENANT === 'amsterdam'
    const canNotDelete = isAmsterdam ? 'cpo' : 'gemeente'

    return getters.roleName !== canNotDelete
  },
  roleDetailsByRole:
    state =>
    ({ role }) =>
      state.roleDetails[role] || [],

  // Merge all role details that are in array format
  roleDetailsMerged: state => {
    return state.roles.reduce(
      (details, role) => (Array.isArray(state.roleDetails[role]) ? details.concat(state.roleDetails[role]) : details),
      []
    );
  },

  roleName: state => {
    let names = [];

    if (state.roles.includes('admin')) {
      names.push('Beheerder');
    } else if (state.roles.includes('cpo')) {
      names.push('CPO');
    } else if (state.roles.includes('municipality')) {
      names.push('Gemeente');
    }


    // RVB
    if (state.roles.includes('customer')) {
      names.push('klant');
    }
    if (state.roles.includes('caseworker')) {
      names.push('medewerker');
    }
    if (state.roles.includes('caseviewer')) {
      names.push('bezoeker');
    }

    // Doesn't actually exist, but we need a fallback that implies no rights are provided
    if (names.length === 0) {
      return 'bezoeker';
    }

    if (names.length === 1) {
      return names[0];
    }

    if (names.length === 2) {
      return names.join(' en ');
    }

    let last = names.pop();
    return `${names.join(', ')} en ${last}`;
  },

  canAccessRequests: (state, getters) => getters.securityCheck({ attribute: 'requestlist' }),
  canAccessRequestLocations: (state, getters) => getters.securityCheck({ attribute: 'requestlocations' }),
  canAccessRealisationProcesses: (state, getters) => getters.securityCheck({ attribute: 'realisationlist' }),
  canAccessAssetManagement: (state, getters) => getters.securityCheck({ attribute: 'canAccessAssetManagement' }),
  canStartRealisationProcess: (state, getters) => getters.securityCheck({ attribute: 'realisationbtn' }),
  canChangeStatus: (state, getters) => getters.securityCheck({ attribute: 'canChangeStatus' }),
  canChangeCommentStatus: (state, getters) => getters.securityCheck({ attribute: 'canChangeCommentStatus' }),
  canModerateComments: (state, getters) => getters.securityCheck({ attribute: 'canModerateComments' }),
  canAutomateViews: (state, getters) => getters.securityCheck({ attribute: 'automatedViews' }),
  canGenerateStartDoc: (state, getters) => getters.securityCheck({ attribute: 'generateStartDoc' }),
  canAccessConfig: (state, getters) => getters.securityCheck({ attribute: 'admin' }),
  canAccessReporting: (state, getters) => getters.securityCheck({ attribute: 'reporting' }),
  canAccessManuals: (state, getters) => getters.securityCheck({ attribute: 'manuals' }),
  canAccessEventLog: (state, getters) => getters.securityCheck({ attribute: 'eventlog' }),
  canConfirmTrafficDecisions: (state, getters) => getters.securityCheck({ attribute: 'canConfirmTrafficDecisions' }),
  canAccessExport: (state, getters) => getters.securityCheck({ attribute: 'export' }),
  canDeleteProcess: (state, getters) => getters.securityCheck({ attribute: 'canDeleteProcess' }),
  canDeleteListedProcesses: (state, getters) => getters.securityCheck({ attribute: 'canDeleteListedProcesses' }),
  canAccessPrivateProcesses: (state, getters) => getters.securityCheck({ attribute: 'privateProcess' }),
  userRole: (state) => state.roles?.[0],

  getRemainingTime: state => state.remainingTime,
  belowSessionWarningTreshold: state => state.remainingTime < 300, // 5 minutes
  getInitialRemainingTime: state => state.initialRemainingTime,
  getUserSettings: state => state.userSettings
};
const actions = {
  saveUserSettings({ commit }, { userSettings }) {
    commit('setUserSettings', userSettings);
  }
};
const mutations = {
  setRoles(state, { roles }) {
    state.roles = Object.keys(roles).filter(
      role => (Array.isArray(roles[role]) && roles[role].length !== 0) || roles[role] === true
    );

    // Raw role data
    // Account for Amsterdam permissions being stated simply as 'true'
    state.roleDetails = Object.keys(roles).reduce((roleDetails, role) => {
      roleDetails[role] = roles[role] === true ? ['*'] : roles[role];
      return roleDetails;
    }, {});

    state.loaded = true;
  },
  /**
   * Remaining time on idToken in seconds
   */
  setRemainingTime(state, { remainingTime }) {
    if (!remainingTime && remainingTime !== 0) return;

    state.remainingTime = remainingTime;

    if (state.initialRemainingTime === null) {
      state.initialRemainingTime = remainingTime;
    }
  },
  setUserSettings(state, userSettings) {
    state.userSettings = userSettings;
  }
};
// Save userSettings to local storage on page unload (refresh, close browser, leave...) //
window.addEventListener('beforeunload', () => {
  if (localStorage.getItem('userSettings')) {
    localStorage.setItem('userSettings', JSON.stringify(state.userSettings));
  }
});

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
