/**
 * Import Dependency
 */
import Realisation from '@/models/Realisation'

import Vue from 'vue'
import { realisationListFields } from '@/config'
import RealisationProcesses from '@/components/Table/configs/RealisationProcesses'

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  /**
   * Locks the UI while loading / refreshing / sending data
   */
  loading: false,
  lastRefresh: '',

  /**
   * The search term in the list view
   */
  searchterm: '',
  currentPage: 1,
  currentStep: 1,
  counts: {
    total: 0,
    current: 0
  },

  /**
   * List of realisation processes
   */
  records: [],
  filteredRecordsByStatus: [],
  realisations: [],
  realisationTableRecords: [],

   /**
    * Keep track of the last viewed realisation (in this session)
    */
  lastOpenedRecord: null,
}

const getters = {
  loading: state => state.loading,
  lastRefresh: state => state.lastRefresh,

  searchterm: state => state.searchterm,
  currentPage: state => state.currentPage,
  currentStep: state => state.currentStep,

  lastOpenedRecord: state => state.lastOpenedRecord,
  getCounts: state => state.counts,

  /**
   * List
   */
  records: state => state.records.sort((a, b) => a.counter > b.counter ? -1: 1),
  hasRecords: state => state.records.length !== 0,
  recordsByStatus: (state, getters) => ({ status, code }) => getters.recordsByCode({ code }).filter(record => !!record.status[status]),
  recordsByActiveStatus: (state, getters) => getters.recordsByStatus({ status: state.activeStatusId }),
  filteredRecordsByStatus: state => state.filteredRecordsByStatus,
  recordsByCode: (state) => ({ code }) => state.records.filter(record => record.MunicipalityCode === code),
  getRealisationTableRecords: (state) => state.realisationTableRecords,

  /**
   * Detail
   */
  hasRecord: state => ({ uuid }) => {
    return Array.isArray(state.records) && state.records.filter(record => record.uuid === uuid).length > 0
  },
  recordByUuid: state => ({ uuid }) => {
    return state.records.find(record => record.uuid === uuid)
    // let records = state.records.filter(record => record.uuid === uuid)
    // return records.length ? records[0] : null
  },
  processByRequestUuid: state => ({ uuid }) => {
    return state.records.find(record => record.requestUuids.includes(uuid))?.uuid
  },
}
const actions = {
  resetRealisationTableRecords ({ commit }, { tableRecords }) {
    commit('resetRealisationTableRecords', { tableRecords })
  },
  deleteRealisation ({ dispatch, commit }, { data }) {
    dispatch('chargingpoints/updateChargingpoint', { location: data.location }, { root: true })
    commit('deleteRealisation', { record: data.record })
  },
  setRealisationTableRecords: ({ commit }, { records }) => {
    commit('setRealisationTableRecords', { records })
  },
  loadRecords: async ({ state, commit, dispatch }, { token, code, after }) => {
    // Reset list if handleRefresh is used
    if (!after) {
      state.realisations = state.records = []
    }
    const api = await fetch('/api/realisationlist', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ after, code }),
    })
    const response = await api.json()

    if (response.data?.cpoError && response.message) {
      dispatch('tenant/setToast', {
        message: response.message,
        error: false,
        variant: 'warning',
        delay: 100
      }, { root: true });
    }
    // Map records before another possible call //
    response.data.records.forEach(record => {
      commit('addRecord', { record })
    })

    //state.realisations = [...state.realisations, ...state.records]
    state.counts.total = response.data.count
    state.counts.current += response.data.records.length

    if (response.data?.after) {
      return await dispatch('loadRecords', { token, after: response.data.after, code })
    }

    commit('setLoadingState', { loading: false })
  },
  setRecords ({ commit }, { records }) {
    commit('setRecords', { records })
  },
  addRecord ({ commit }, { record }) {
    commit('addRecord', { record })
  },
  loadRecordsByCode: async  ({ commit }, { token, code }) => {
    const api = await fetch('/api/realisationlist_paged', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        codes: [code],
      }),
    })
    if (! api.ok) {
      throw new Error('failed')
    }

    const response = await api.json()
    const records = response.data.records.map(record => {
      return new Realisation({ data: record.data, ref: record.ref['@ref'].id })
    })

    commit('replaceRecordsByCode', {
      records, code,
    })

    return records
  },

  loadFilteredRecords ({ commit }) {

    commit('filterRecordsByStatus', { records: state.records })
  },
  filterRealisations ({ commit }, filters) {
    commit('setFilteredRealisations', filters)
  },
  updateCurrentStep ({ commit }, step) {
    commit('setCurrentStep', step)
  },
  async addNewRecord ({ commit, dispatch }, { record }) {
    const newRecord = await commit('addRecord', { record })
    if (newRecord) {
      dispatch('realisation/setSavedRecordValues', { values: newRecord.values.Location })
    }

    dispatch('EVtables/loadRecords',
      { identifier: 'realisation.realisation', records: state.records.map(RealisationProcesses.dataMapper).slice() },
      { root: true },
    )
  },
}
const mutations = {
  resetRealisationTableRecords (state, { tableRecords }) {
    state.realisationTableRecords = tableRecords
  },
  setRealisationTableRecords (state, { records }) {
    state.realisationTableRecords = records
  },
  setLoadingState(state, { loading }) {
    state.loading = loading
  },
  setLastOpenedRecord(state, { uuid }) {
    state.lastOpenedRecord = uuid
  },
  setRecords(state, { records }) {
    state.records = records
    const date = new Date(Date.now())
    state.counts = {
      total: 0,
      current: 0
    }
    state.lastRefresh = `${(`0${date.getHours()}`.slice(-2))}:${(`0${date.getMinutes()}`.slice(-2))}:${(`0${date.getSeconds()}`.slice(-2))}`
  },

  /**
   * Replace all records for a municipality by municipality code
   *  If none are set, it simply adds the additional records to the collection
   */
  replaceRecordsByCode(state, { records, code }) {
    state.records = (state.records || [])
      .filter(record => record.MunicipalityCode !== code)
      .concat(records)

    const date = new Date(Date.now())
    state.lastRefresh = `${(`0${date.getHours()}`.slice(-2))}:${(`0${date.getMinutes()}`.slice(-2))}:${(`0${date.getSeconds()}`.slice(-2))}`
  },

  setSearchterm(state, { term }) {
    state.searchterm = term
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setCurrentStep(state, step) {
    state.currentStep = step
  },

  replaceRecord(state, { record }) {
    const recordIndex = state.records.findIndex(current => current.uuid === record.data.uuid)
    if (recordIndex !== -1) {
      const savedRecord = new Realisation({ data: record.data, ref: record.ref['@ref'].id })
      state.records.splice(recordIndex, 1, savedRecord)
    }

    const mappedRecordIndex = state.realisationTableRecords.findIndex(current => current.uuid.value === record.data.uuid)
    if (mappedRecordIndex !== -1) {
      const mappedRecord = RealisationProcesses.dataMapper(new Realisation({ data: record.data, ref: record.ref['@ref'].id }))
      state.realisationTableRecords.splice(mappedRecordIndex, 1, realisationListFields({ record: mappedRecord }))
    }
  },
  addRecord(state, { record }) {
    let records = state.records
    const newRecord = new Realisation({ data: record.data, ref: record.ref['@ref'].id })

    records.push(newRecord)
    const mappedRecord = RealisationProcesses.dataMapper(newRecord)
    state.realisationTableRecords.push(realisationListFields({ record: mappedRecord }))
    Vue.set(state, 'records', records)

    return newRecord
  },

  deleteRealisation (state, { record }) {
    const index = state.realisationTableRecords.findIndex(current => current.uuid.value === record.uuid)
    if (index !== -1) {
      state.realisationTableRecords.splice(index, 1)
    }
    const recordIndex = state.records.findIndex(current => current.uuid === record.uuid)
    if (recordIndex !== -1) {
      state.records.splice(recordIndex, 1)
    }
  },

  updateRecordComments(state, { ref, comments }) {
    let index = state.records.findIndex(record => record.ref === ref)
    if (index < 0) return

    let record = state.records[index]

    record.comments = comments
  },
  updateRecordLocation(state, { record }) {
    const realisation = new Realisation({ data: record.data, ref: record.ref['@ref'].id })
    let index = state.records.findIndex(currentRecord => currentRecord.ref === realisation.ref)
    if (index < 0) return

    let currentRecord = state.records[index]
    state.records[index].address = realisation.address || 'Geen locatie gekozen'

    currentRecord.CurrentLocation.Location.chargingpointUuid = realisation.CurrentLocation.Location.chargingpointUuid
    currentRecord.CurrentLocation.Location.chargingpointStatus = realisation.CurrentLocation.Location.chargingpointStatus
  },
  filterRecordsByStatus (state, { records }) {
    state.filteredRecordsByStatus = records
  },
  setFilteredRealisations (state, filters) {
    state.filteredRecordsByStatus = state.records.filter(record => record.MunicipalityCode === filters.activeMunicipality)

    const values = Object.values(filters).some(filter => Array.isArray(filter) && filter.length)
    const types = filters.realisations?.map(rp => rp.type)

    if (values && !types.length) {
      return state.filteredRecordsByStatus = []
    }
    if (!values) {
      return
    }

    state.filteredRecordsByStatus = types.length
      ? state.filteredRecordsByStatus.filter(realisation => {
          const statuses = ['completed', 'cancelled', 'onhold']
          const isActive = types.includes('active') && statuses.every(status => !realisation.status[status])

          if (isActive) {
            return true
          }
          return types.some(status => !!realisation.status[status])
        })
      : state.records
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
