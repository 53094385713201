import store from '@/store'
import router from '../router'
import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';
import { finUnit, gridConnectionValues } from '@/config';
import { getGridOperators } from '@/services/gridoperators';
import { capitalize } from '@/helpers/string'

const sort = array => {
  return array.sort((a, b) => a.localeCompare(b))
}

const municipalityOptions = () => {
  const hasTenantPrivateProcess = store.getters['tenant/getCategoryOptions'].some(type => type.value === 'private')
  const municipalityOptions = (hasTenantPrivateProcess
    ? allMunicipalityOptions
    : store.getters['tenant/getMunicipalityOptions']).map(mun => mun.text)

  return sort(municipalityOptions)
}

export default {
  setDate: ({ fieldValue }) => {
    return fieldValue ? new Date(fieldValue).toLocaleDateString('es-CL') : '-'
  },

  setPostalCode: ({ fieldValue }) => {
    return fieldValue ? fieldValue.replace(/\s/g, '').replace(/\d{4}/, '$& ') : '-'
  },

  setContractor: ({ fieldValue }) => {
    return fieldValue ? store.getters['tenant/getContractorByUuid']({ uuid: fieldValue })?.label : '-'
  },

  setLink: ({ process }) => {
    const uuid = process.processUuid
    const name = process.type === 'realisation' ? 'Realisation' : 'Process'
    uuid && router.push({ name, params: { uuid } })
  },

  setProcessType: ({ fieldValue }) => {
    const process = store.getters['tenant/getProcessTypes'].find(process => process.value === fieldValue)
    return capitalize(process.routeType)
  },

  setCurrentStep: ({ fieldValue, process }) => {
    return process.isCompleted ? 'Afgerond' : fieldValue
  },

  setStepLabels: () => {
    const processSteps = store.getters['tenant/getSteps'].map(step => `${step.step} ${step.label}`)
    const last = store.getters['tenant/getSteps'].at(-1)
    processSteps.push(`${last.step} Afgerond`)

    return processSteps
  },

  setGridOperatorAddress: ({ fieldValue }) => {
   if (fieldValue && fieldValue?.StreetName) {
     return `${fieldValue?.StreetName} ${fieldValue?.HouseNumber}${fieldValue?.HouseNumberSuffix}, ${fieldValue?.PostalCode} ${fieldValue?.CityName}`
   }
  },
  // Field name based functions (LocationList)
  city: () => municipalityOptions(),
  finUnit: () => finUnit,
  domain: () => ['Publiek', 'Privaat'],
  gridOperator: () => sort(getGridOperators().map(op => op.text)),
  //

  setMunicipalityOptions: () => municipalityOptions(),
  setCpoOptions: () => sort(Object.keys(store.getters['tenant/getCpos']).map(cpo => store.getters['tenant/getCpos'][cpo].name)),
  setChargingpointStatuses: () => sort(store.getters['tenant/getChargingpointStatuses']),
  setCategoryOptions: () => sort(store.getters['tenant/getCategoryOptions'].map(c => c.text)),
  setGridConnectionValues: () => gridConnectionValues,
  setCharchingStationVersions: () => sort(store.getters['tenant/getCharchingStationVersions']),
  setContractors: () => sort(store.getters['tenant/getEnabledContractors'].map(c => c.label)),
  setTagOptions: () => sort(store.getters['tenant/getExistingTags'].map(c => c.label)),
  setFinUnitOptions: () => sort(finUnit),
  setStatusOptions: () => ['Actief', 'On hold', 'Afgerond', 'Geannuleerd'],
  setPowerTypeOptions: () => ['AC', 'DC'],
  setVkbStatusOptions: () => sort(store.getters['tenant/getVkbStatuses'])
}
