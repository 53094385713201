<template>
  <div class="Realisation">
    <!-- <CategorySelectionModal v-if="newProcess" :selected="category" @confirmSelection="handlesetCategory" /> -->
    <router-view
      v-if="record"
      :key="$route.path"
    />
    <div
      v-else
      class="spinner-full-window d-flex"
    >
      <b-spinner
        variant="secondary"
        class="spinner"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fetchAssetProcess, fetchRealisationProcess, fetchAssetByLocationId } from '@/services/processes'

export default {
  name: 'Process',
  data() {
    return {
      filteringStorePoints: [
        'chargingpoints/filterChargingPoints',
        'realisations/filterRealisations',
        'requests/filterRequests'
      ],
      processType: '',
      record: null
    };
  },
  computed: {
    ...mapGetters('user', ['canAccessRealisationProcesses']),
    ...mapGetters('tenant', ['getProcessTypes', 'getSteps']),
    ...mapGetters('processes', ['getSelectedProcess', 'getSelectedAsset', 'getCurrentProcessType']),

    currentStep() {
      return this.record?.flow ? this.record.flow.state.filter(step => step.started_at).length.toString() : null;
    },
    isNewProcess() {
      return this.$route.name.startsWith('new-');
    },
    isSelectedProcess() {
      return this.getSelectedProcess?.locationId?.full === this.$route.params.locationId
        || !this.isNewProcess && (this.getSelectedProcess?.uuid === this.$route.params.uuid)
    },
    assetProcessType() {
      return this.$route.params.type &&
        this.getProcessTypes.find(process => process.label.toLowerCase() === this.$route.params.type)
    }
  },
  watch: {
    '$route': {
      async handler (route) {
        this.$store.dispatch('processes/setCurrentProcessType', {
          processType: this.getSelectedProcess.processType?.value || 'realisation'
        })

        // When called from PopUpChargingPoint //
        if (!this.isNewProcess && route.params.uuid !== this.getSelectedProcess.uuid) {
          const token = await this.$auth.getTokenSilently()
          await fetchAssetProcess({ token, uuid: route.params.uuid })
            .catch(err => console.log(err))
        }
      }
    }
  },

  async created() {
    const token = await this.$auth.getTokenSilently()
    if (this.isNewProcess) {
      const locationId = this.$route.params.locationId

      await fetchRealisationProcess({ token, locationId, processType: this.assetProcessType })
        .catch(err => console.log(err))
    } else {
      const uuid = this.$route.params.uuid

      await fetchAssetProcess({ token, uuid })
        .catch(err => console.log(err))
    }

    const selectedAsset = this.getSelectedAsset?.locationId === this.getSelectedProcess?.locationId?.full

    if (!selectedAsset && this.getSelectedProcess) {
      await fetchAssetByLocationId({ token, locationId: this.getSelectedProcess?.locationId?.full })
        .catch(err => console.log(err))
    }

    this.$store.dispatch('processes/setCurrentProcessType', {
      processType: this.getSelectedProcess.processType?.value || 'realisation'
    })

    this.record = this.getSelectedProcess

    // If new process route, redirect if process type doesn't match //
    if (this.isNewProcess && !this.$router.currentRoute.name.includes(this.getSelectedProcess.processType?.value)) {
      const processType = this.getSelectedProcess.processType
      this.$router.replace({
        name: `new-${processType.value}`
      });
    }

    if (this.isNewProcess || this.$route.params.step === this.currentStep) return;

    if (this.$route.params?.uuid && !(this.$route.params.step || this.$route.query.step)) {
      const processType = this.getSelectedProcess.processType

      if (['private'].includes(processType.value)) {
        if (this.currentStep === this.$route.query.step) return;
        this.$router.replace({
          name: `${processType.value}Steps`,
          params: { type: processType.routeType, uuid: this.$route.params.uuid },
          query: { step: this.currentStep }
        });
      } else {
      this.$router.replace({
          name: `${processType.value}Step${this.currentStep}`,
          params: { type: processType.routeType, step: this.currentStep }
        });
      }
    }
  },

  beforeDestroy() {
    this.resetAllFiltering();
  },
  methods: {
    resetAllFiltering() {
      const filters = {
        chargingpoints: [],
        realisations: [],
        requests: []
      };
      this.filteringStorePoints.forEach(store => this.$store.dispatch(store, filters));
      // Remove selected process
      this.$store.dispatch('processes/setSelectedProcess', { process: null })

      this.$nextTick(() => {
        this.$store.dispatch('chargingpoints/loadSelectedFilterValues', { filterValues: null });
      });
    }
  }
};
</script>
