<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="ItemList">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <DataTable
        ref="table-counts"
        :key="tableKey"
        :value="records"
        :filters.sync="filters"
        :global-filter-fields="globalSearchFields"
        @value-change="handleStatusCounts"
      />
      <div class="d-flex align-items-center">
        <div class="status-filters d-flex gap-1">
          <div
            v-for="(filter, key) in statusFilters.filters"
            :key="key"
            class="d-flex align-items-center"
          >
            <span
              v-if="filter.stepGroup"
              class="mr-2 ml-3"
            />
            <Button
              type="button"
              :class="selectedStatusFilter === key ? 'p-button-primary p-button-sm' : statusFilters.class"
              :label="filter.label"
              :disabled="isDataLoading"
              :badge="String(filter.count)"
              :badge-class="`p-badge-${selectedStatusFilter === key ? 'primary' : filter.badge || 'secondary'}`"
              @click="handleFilterProcesses({ filter: key })"
            />
          </div>
        </div>
      </div>
      <span class="mr-3 d-flex ml-auto align-items-center">
        <span
          v-if="showCounts"
          class="mr-3"
        >{{ getCounts.current }} / {{ getCounts.total }}</span>
        <Button
          v-tooltip.left="{ value: 'Bijwerken' }"
          type="button"
          :disabled="isRefreshing"
          :class="['p-button px-3 p-button-secondary p-button-outlined']"
          @click="handleRefresh"
        >
          <i class="fa fa-refresh" />
        </Button>
      </span>
      <h4
        v-if="selectedProcessType"
        class="m-0"
      >
        {{ `${selectedProcessType.label}sen` }}
      </h4>
    </div>
    <div class="card realisations">
      <DataTable
        ref="table"
        :key="tableKey"
        :value="realisations"
        :paginator="true"
        class="p-datatable-sm editable-cells-table realisations-table"
        :rows="perPage"
        :first="first"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[10, 20]"
        current-page-report-template="{first} tot {last} van {totalRecords}"
        :striped-rows="!isDataLoading"
        data-key="uuid.value"
        :filters.sync="filters"
        filter-display="menu"
        removable-sort
        show-gridlines
        :scrollable="true"
        scroll-height="calc(100vh - 19rem)"
        responsive-layout="scroll"
        :reorderable-columns="true"
        :resizable-columns="true"
        :auto-layout="true"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :sort-mode="sortMode"
        selection-mode="multiple"
        :selection.sync="selectedRows"
        :meta-key-selection="false"
        :multi-sort-meta="multiSortMeta"
        column-resize-mode="expand"
        :table-style="{ 'min-width': `${tableWidth}px`, 'width': `${tableWidth}px`, 'pointer-events': `${isDataLoading ? 'none' : 'auto'}` }"
        :global-filter-fields="globalSearchFields"
        @sort="handleColumnSort"
        @page="handlePagination"
        @column-reorder="handleColumnReorder"
        @column-resize-end="handleColumnResize"
        @value-change="handleValueChanged"
      >
        <template #header>
          <div class="d-flex py-2 gap-1">
            <Button
              v-tooltip.right="{ value: 'Wis alle filters' }"
              type="button"
              icon="pi pi-filter-slash"
              :class="['p-button', { 'p-disabled p-button-secondary p-button-outlined': !isFiltered }]"
              @click="clearAllFilters()"
            />
            <span class="p-input-icon-left p-input-icon-right mr-auto w-25">
              <i class="pi pi-search no-events" />
              <InputText
                :value="filters.global.value"
                placeholder="Zoeken"
                class="w-100"
                @input="handleInput"
              />
              <i
                v-if="filters.global.value"
                class="pi pi-times pointer"
                @click="filters.global.value = ''"
              />
            </span>
            <span>
              <Button
                v-if="canDeleteListedProcesses"
                v-tooltip.top="{ value: selectedRows.length ? 'Alles deselecteren' : 'Alles selecteren' }"
                type="button"
                icon="pi pi-check-square"
                :class="['p-button px-3', { 'p-button-secondary p-button-outlined': !selectedRows.length }]"
                @click="handleSelectedRecords"
              />
            </span>
            <span>
              <Button
                id="resetTable"
                v-tooltip.top="{ value: 'Herstel tabel' }"
                type="button"
                icon="pi pi-replay"
                :class="['p-button px-3', { 'p-disabled p-button-secondary p-button-outlined': !isTableChanged }]"
                @click="resetTableSettings()"
              />
            </span>
            <span style="position: relative">
              <MultiSelect
                id="selectionColumns"
                :value="activeColumns"
                :options="defaultColumns"
                scroll-height="calc(80vh - 7em)"
                option-label="label"
                placeholder="Selecteer tabel velden"
                class="selectable-fields w-100"
                @change="handleActiveColumns"
              >
                <template #value>
                  Selecteer kolommen
                </template>
              </MultiSelect>
            </span>
            <span>
              <Button
                v-if="canAccessExport"
                type="button"
                icon="pi pi-external-link"
                label="Export"
                class="p-button p-button-success"
                @click="exportCsv()"
              />
            </span>
            <span>
              <RealisationDelete
                v-if="canDeleteListedProcesses"
                v-tooltip.left="{ value: deleteProcessTooltip, disabled: !selectedRows.length }"
                :prime="true"
                :uuids="selectedRowsUuids"
                @realisationDeleted="handleProcessDeleted"
                @allRealisationsDeleted="setStatusFiltersCounts"
              />
            </span>
          </div>
        </template>
        <template #empty>
          Geen processen gevonden.
        </template>
        <Column
          v-for="(column, index) in activeColumns"
          :id="column.field"
          :key="`${column.field}-${index}`"
          :field="column.field"
          :sort-field="`${column.field}.value`"
          :filter-field="`${column.field}.value`"
          :max-constraints="3"
          :hidden="column.hidden"
          :filter-match-mode-options="filterOptions[column.field] ? matchModes : null"
          :show-filter-match-modes="!filterOptions[column.field]"
          :show-filter-operator="!filterOptions[column.field]"
          :show-add-button="!filterOptions[column.field]"
          :show-apply-button="!filterOptions[column.field]"
          :show-clear-button="!filterOptions[column.field]"
          :sortable="true"
          :frozen="index === 0 && frozenColumns.includes(column.field)"
          align-frozen="left"
          :header-class="column.field"
          :body-style="'padding: 0.3vh 0.5rem;'"
          :styles="{ 'flex': `0 0 ${column.width}px` }"
        >
          <template #header>
            {{ column.label }}
            <span
              v-if="index === 0"
              :class="`pi pi-lock${!frozenColumns.includes(column.field) ? '-open' : ''} freeze`"
              @click="handleFrozenColumns({ column: column.field })"
            />
          </template>
          <template
            v-if="!column.filterDisabled"
            #filter="{ filterModel, filterCallback }"
          >
            <div
              v-if="filterOptions[column.field]"
              style="max-height: calc(50vh - 7em); overflow: auto; padding:0 0.5em; max-width: 300px;"
            >
              <strong class="d-block pb-2">Selecteer {{ column.label.toLowerCase() }}</strong>
              <span
                v-if="filterOptions[column.field].length > 15 || filterSearchField"
                class="p-input-icon-right mb-2 filter-search"
              >
                <div class="p-input-icon-right">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filterSearchField"
                    type="text"
                    placeholder="Zoek"
                    @input="$event => handleFilterItemsSearch($event, column)"
                  />
                </div>
                <div
                  v-if="filterModel.value"
                  class="filter-chips d-flex py-2"
                >
                  <Chip
                    v-for="chip in filterModel.value"
                    :key="chip"
                    :label="chip"
                    removable
                    class="chip-small"
                    @remove="handleFilterCallback(filterCallback, chip, filterModel)"
                  />
                </div>
              </span>
              <div
                v-for="filter in filterOptions[column.field]"
                :key="filter"
                class="d-flex align-items-center my-1"
              >
                <Checkbox
                  :id="filter"
                  v-model="filterModel.value"
                  :input-id="filter"
                  :name="filter"
                  :value="filter"
                  @change="filterCallback()"
                />
                <label
                  :for="filter"
                  role="button"
                  class="ml-2 mb-0 flex-fill"
                >{{ filter }}</label>
              </div>
            </div>
            <InputText
              v-else
              v-model="filterModel.value"
              type="text"
              class="p-column-filter"
              placeholder="Zoek"
            />
          </template>
          <template #body="{ data }">
            <div v-if="!isDataLoading">
              <span
                v-if="data[column.field].link"
                class="d-flex align-items-center"
              >
                <i class="pi pi-link mr-1 mt-1" />
                <router-link
                  :to="{ name: getRouteName, params: { uuid: data[column.field].link } }"
                  v-html="setColumnValue(column, data[column.field])"
                />
              </span>
              <span v-else>
                <img
                  v-if="data[column.field].icon"
                  :src="getImage(data[column.field].icon)"
                  width="15"
                  class="mr-2"
                >
                <span v-html="setColumnValue(column, data[column.field])" />
              </span>
            </div>
            <Skeleton v-else />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RealisationDelete from '@/components/realisation/RealisationDelete'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import Tooltip from 'primevue/tooltip'
import Skeleton from 'primevue/skeleton'
import Chip from 'primevue/chip';
import Checkbox from 'primevue/checkbox'
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api/'
import { fetchProcessesByType, clearTempProcesses } from '@/services/processes'
import recordList from '@/helpers/recordList'

import { exportCsv, defaultRealisationTableFields, defaultRealisationStatusFilters } from '@/config'
import { downloadExcel } from '@/services/exportCsv'
import { image } from '@/helpers/assets'

export default {
  name: 'Realisations',
  components: {
    RealisationDelete,
    DataTable,
    Column,
    InputText,
    Button,
    MultiSelect,
    Skeleton,
    Checkbox,
    Chip
  },
  directives: {
    'tooltip': Tooltip,
  },
  data() {
    return {
      filters: {
        global: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      isDataLoading: true,
      matchModes: [
        { label: 'filterByArrayEquals', value: 'filterByArrayEquals' },
        { label: 'commaSeparatedArrayOR', value: 'commaSeparatedArrayOR', method: 'some' },
        { label: 'commaSeparatedArrayAND', value: 'commaSeparatedArrayAND', method: 'every' },
      ],
      realisations: [],
      filteredRecords: [],
      page: 1,
      perPage: 10,
      debounceTimeout: null,
      defaultColumns: [],
      activeColumns: [],
      realisationsStorage: null,
      tableWidth: 0,
      tableKey: 0,
      sortMode: 'single', //or multiple
      sortField: '',
      sortOrder: 1,
      multiSortMeta: [],
      frozenColumns: ['reference'],
      selectedRows: [],
      selectedStatusFilter: 'all',
      image,
      filterOptions: {},
      filterSearchField: '',

      statusFilters: {
        filters: {
          all: { label: 'Alles', count: 0 },
          active: { label: 'Actief', count: 0 },
          onhold: { label: 'On hold', count: 0 },
          completed: { label: 'Afgerond', count: 0 },
          cancelled: { label: 'Geannuleerd', count: 0 },
          municipality: { label: 'Gemeente', count: 0, badge: 'success' },
          cpo: { label: 'CPO', count: 0, badge: 'success' },
        },
        class: 'p-button-sm p-button-outlined p-button-secondary',
      },
      currentTenant: process.env.VUE_APP_TENANT,
      defaultRealisationTableFields: [],
      deletedRecords: [],
      selectedProcessType: {},
      isRefreshing: false,
      excludeFromGlobalSearch: [
        'street',
        'houseNumber',
        'houseNumberSuffix',
        'city',
        'tagcount',
        'requestCount',
        'numberOfChargingStations',
        'powerType'
      ],
      storageVersion: 1.12, // Force storage update when needed
    }
  },
  computed: {
    ...mapGetters('realisations', ['getRealisationTableRecords']),
    ...mapGetters('user', ['canAccessRealisationProcesses', 'canAccessExport', 'canDeleteListedProcesses', 'userRole']),
    ...mapGetters('tenant', ['getProcessTypes']),
    ...mapGetters('processes', ['getProcessTableRecords', 'getCurrentProcessType', 'getCounts']),

    globalSearchFields() {
      return this.activeColumns?.filter(column =>
        !column.options &&
        !this.excludeFromGlobalSearch.includes(column.field) &&
        !column.matchMode
      ).map(column => `${column.field}.value`)
    },
    first() {
      return (this.page - 1) * this.perPage
    },
    showCounts() {
      return this.isRefreshing && this.records.length >= 500 // Fauna query size
    },
    getDefaultColumns() {
      return JSON.parse(JSON.stringify(this.defaultRealisationTableFields))
    },
    isFiltered() {
      return Object.keys(this.filters).some(f => this.filters[f].value?.length || this.filters[f]?.constraints?.some(c => c.value?.length))
    },
    isTableChanged() {
      const defaultColumns = this.defaultRealisationTableFields.filter(field => !field.fields)
      const activeColumns = this.activeColumns?.filter(column => !column.hidden)
      const tableChanged = activeColumns.length && defaultColumns.some((column, index) => {
        const activeColumn = activeColumns[index]
        return activeColumn && ((column.field !== activeColumn.field) || (column.width !== activeColumn.width))
      })

      return activeColumns.length &&
        (tableChanged || (this.realisationsStorage && this.realisationsStorage.version !== this.storageVersion))
    },
    // isTableChanged () {
    //   return JSON.stringify(this.getActiveColumns) !== JSON.stringify(this.getDefaultColumns) ||
    //          this.realisationsStorage.version !== this.storageVersion
    // },
    isUserCPO() {
      return this.$auth.user['https://evtools.nl/identity']?.category === 'cpo'
    },
    isAssetProcess() {
      return this.selectedProcessType && this.selectedProcessType?.value !== 'realisation'
    },
    deleteProcessTooltip() {
      const s = this.selectedRows.length > 1 ? 's' : ''
      return `Verwijder geselecteerde realisatie${s}`
    },
    selectedRowsUuids() {
      return this.selectedRows
        .filter(row => row.currentStatus.value !== 'Afgerond').map(row => row.uuid.value)
    },
    records() {
      return this.isAssetProcess
        ? this.getProcessTableRecords
        : this.getRealisationTableRecords
    },
    getRouteName() {
      return this.isAssetProcess ? 'Process' : 'Realisation'
    },
    isRealisationRoute() {
      return this.selectedProcessType?.value === 'realisation'
    },
    userMunicipalityCode() {
      if (process.env.VUE_APP_TENANT === 'amsterdam') return false
      return this.userRole === 'municipality'

    },
    restrictedAccess() {
      if (process.env.VUE_APP_TENANT === 'amsterdam') return false

      return this.$auth.user['https://evtools.nl/roles'].find(role => {
        return role.tenant === process.env.VUE_APP_TENANT && role[this.userRole]?.[0] !== '*'
      })
    },
  },
  watch: {
    activeColumns: {
      deep: true,
      handler(activeColumns) {
        if (activeColumns && this.realisationsStorage) {
          this.realisationsStorage.activeColumns = activeColumns
          localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
        }
      },
    },
    filters: {
      deep: true,
      handler(filters) {
        if (this.filters) {
          Object.keys(this.filters).map(filter => {
            if (this.filters[filter].constraints && !this.filters[filter].operator) {
              this.filters[filter].operator = FilterOperator.OR
              this.filters[filter].constraints[0].matchMode = FilterMatchMode.CONTAINS
            }
          })
        }
        this.realisationsStorage.filters = filters
        localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
      },
    },
    selectedRows: {
      handler(rows) {
        this.realisationsStorage.selectedRows = rows
        localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
      },
    },
    '$route.params.type': {
      immediate: true,
      async handler(route) {
        this.setRecords(route)
        this.$nextTick(() => {
          if (route !== this.selectedRouteType) {
            this.clearAllFilters()
            this.realisationsStorage.selectedRouteType = this.selectedRouteType = route
            localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
          }
        })
      }
    }
  },
  created() {
    this.defaultRealisationTableFields = defaultRealisationTableFields(this.isUserCPO)
    this.realisationsStorage = JSON.parse(localStorage.getItem('realisationList'))
    const version = this.realisationsStorage?.version || 0
    this.defaultColumns = JSON.parse(JSON.stringify(this.defaultRealisationTableFields))

    if (this.realisationsStorage) {
      this.activeColumns = this.realisationsStorage.activeColumns
      this.statusFilters.filters = defaultRealisationStatusFilters
      this.selectedStatusFilter = this.realisationsStorage.selectedStatusFilter// || 'active'
      this.selectedRouteType = this.realisationsStorage.selectedRouteType

      if (this.storageVersion !== version || this.currentTenant !== this.realisationsStorage.tenant) {
        localStorage.removeItem('realisationList')
        this.resetTableSettings()
      }
    } else {
      this.setDefaultRealisationFields()
      this.setTableFilters()
    }

    this.registerDropdownFilters()

  },
  mounted() {
    this.setStorageValues()
    this.setTableWidth()
    this.setFrozenColumns()
    this.setDropdownColumnFields()
  },
  beforeDestroy() {
    this.removeActiveMenuFilter()
    clearTimeout(this.debounceTimeout)
  },
  methods: {
    setRecords(route, refresh) {
      this.selectedProcessType = this.getProcessTypes.find(process => process.routeType === route)

      if (this.selectedProcessType) {
        this.$store.dispatch('processes/setCurrentProcessType', { processType: this.selectedProcessType.value })
      }
      this.isDataLoading = true
      this.realisations = this.getRealisationTableRecords.slice(0, this.perPage)

      setTimeout(async () => {
        if (!this.isRealisationRoute || refresh) { // || !this.getProcessTableRecords.length) {
          const token = await this.$auth.getTokenSilently()
          const processType = this.selectedProcessType.value
          this.isRefreshing = true

          const restrictedAccess = this.restrictedAccess || this.userMunicipalityCode

          if (restrictedAccess) {
            const codes = Object.values(restrictedAccess).find(val => Array.isArray(val) && val.length)

            await Promise.all(
              codes.map(async code => {
                await fetchProcessesByType({ token, processType, code })
                  .catch(err => console.log('Could not load processes for given municipality', err))
              })
            );

            clearTempProcesses()
          } else {
            await fetchProcessesByType({ token, processType }).catch(err => console.log(err))
          }

          this.isRefreshing = false
        }

        this.realisations = this.records
          .filter(record => record.status.value[this.selectedStatusFilter])
          .sort((a, b) => b.updated_at_nr.value - a.updated_at_nr.value)

        this.isDataLoading = false
        this.isRefreshing = false
        this.setStatusFiltersCounts()

        this.activeColumns.map(column => {
          if (column.fn && recordList[column.fn]) {
            this.$set(this.filterOptions, column.field, recordList[column.fn]())
          }
        })
      })
    },

    handleValueChanged(records) {
      // Used for e.g Export
      this.filteredRecords = records
    },

    // Chips (tags) in filter drop-downs //
    handleFilterCallback(filterCallback, filterValue, filterModel) {
      const valueIndex = filterModel.value.findIndex(value => value === filterValue)
      filterModel.value.splice(valueIndex, 1)

      filterCallback()
    },

    setDate(value) {
      return value ? new Date(value).toLocaleDateString('es-CL') : '-'
    },

    setColumnValue(column, field) {
      // If there is a custom field function to evaluate value (config e.g fn: setDate)
      const value = field.fn ? this[field.fn](field.value) : field.value

      return value ? this.highlightSearchTerm(value, column.field) : '-'
    },

    registerDropdownFilters() {
      this.matchModes.forEach(mode => {
        FilterService.register(mode.value, (data, value) => {
          if (!value?.length) {
            return true
          }
          // Dropdown with comma-separated values matching (e.g. Tags)
          const terms = data?.split(', ')
          const OR = mode.value.endsWith(FilterOperator.OR)
          const matchedValue = mode.method
            ? (value[mode.method](val => terms?.includes(val)) || OR && value.includes(data))
            : value.includes(data) // The usual dropdown (e.g Step => always OR operator)

          return matchedValue
        })
      })
    },

    handleSelectedRecords() {
      // Max. perPage (visible records) selection allowed atm.
      this.selectedRows = !this.selectedRows.length ? this.filteredRecords.slice(0, this.perPage) : []
    },

    hasFilterValue(column) {
      if (column.options || !this.filters[`${column.field}.value`]) return false

      return this.filters[`${column.field}.value`].constraints.some(constraint => constraint.value)
    },

    setStatusFiltersCounts(records) {
      const realisations = records || this.realisations
      Object.values(this.statusFilters.filters).forEach(filter => filter.count = 0)

      realisations.map(record => {
        const statuses = record.status.value
        Object.keys(statuses).forEach(status => statuses[status] ? this.statusFilters.filters[status].count++ : null)
      })
    },

    handleProcessDeleted({ record }) {
      const index = this.selectedRows.findIndex(row => row.uuid.value === record.uuid)
      const recordIndex = this.realisations.findIndex(rec => rec.uuid.value === record.uuid)

      if (index !== -1) {
        this.selectedRows.splice(index, 1)
      }

      if (recordIndex !== -1) {
        this.realisations.splice(recordIndex, 1)
      }
    },

    handleRefresh() {
      this.setRecords(this.selectedProcessType.routeType, true)
    },

    handleFilterItemsSearch(event, column) {
      this.filterOptions[column.field] = event?.length >= 3
        ? recordList[column.fn]().filter(option => option.toLowerCase().includes(event.toLowerCase()))
        : recordList[column.fn]()
    },

    handleStatusCounts(records) {
      this.setStatusFiltersCounts(records)
    },

    getImage(image) {
      const name = `chargingpoint-${image.trim()}`
      return this.image({ name })
    },

    setStorageValues() {
      this.perPage = this.realisationsStorage.perPage || 10
      this.page = this.realisationsStorage.page || 1
      this.filters = this.realisationsStorage.filters
      this.multiSortMeta = this.realisationsStorage.multiSortMeta
      this.sortField = this.realisationsStorage.sortField
      this.sortOrder = this.realisationsStorage.sortOrder
      this.selectedRows = this.realisationsStorage.selectedRows
      this.selectedRouteType = this.realisationsStorage.selectedRouteType
    },

    exportCsv() {
      exportCsv.worksheetColumns = []

      this.activeColumns.map(column => {
        exportCsv.worksheetColumns.push({
          header: column.label,
          key: column.field,
          width: Math.ceil(column.width / 10), // approx. translation to Excel column width
        })
      })

      const records = JSON.parse(JSON.stringify(this.filteredRecords))

      records.map(record => {
        // Use field's value function (fn) if present, before exporting (e.g. setDate)
        Object.values(record).map(field => field.fn ? field.value = this[field.fn](field.value) : field.value)
      })
      exportCsv.file.name = 'Processen'
      exportCsv.file.acronym = this.selectedProcessType.acronym //'RP'

      downloadExcel({ config: exportCsv, records })
    },

    handleFrozenColumns({ column }) {
      const index = this.frozenColumns.findIndex(c => c === column)
      index !== -1
        ? this.frozenColumns.splice(index, 1)
        : this.frozenColumns.push(column)
    },

    setFrozenColumns() {
      const [firstColumn] = this.activeColumns
      this.frozenColumns = [firstColumn.field]
    },

    setDropdownColumnFields() {
      this.defaultColumns.sort((a, b) => {
        return this.activeColumns.findIndex(f => f.field === a.field) - this.activeColumns.findIndex(f => f.field === b.field)
      })
    },

    setDefaultRealisationFields() {
      this.defaultColumns = JSON.parse(JSON.stringify(this.defaultRealisationTableFields))
      this.activeColumns = this.defaultColumns
      this.page = 1
      this.perPage = 10

      this.realisationsStorage = {
        activeColumns: this.activeColumns,
        filters: this.filters,
        page: this.page,
        perPage: this.perPage,
        selectedStatusFilter: this.selectedStatusFilter,
        selectedRouteType: this.selectedRouteType,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        multiSortMeta: this.multiSortMeta,
        tenant: this.currentTenant,
        version: this.storageVersion,
        selectedRows: this.selectedRows,
      }
      this.replaceRoute({ filter: this.selectedStatusFilter })

      localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
    },

    handleColumnResize(event) {
      const [column] = event.element.classList
      const fieldIndex = this.activeColumns.findIndex(f => f.field === column)
      const width = this.activeColumns[fieldIndex].width + event.delta

      event.element.style = `flex: 0 0 ${width}px`
      this.activeColumns[fieldIndex].width = width
      this.setFrozenColumns()
    },

    handleColumnReorder(event) {
      const [movedField] = this.activeColumns.splice(event.dragIndex, 1)
      this.activeColumns.splice(event.dropIndex, 0, movedField)

      this.setFrozenColumns()
      this.setDropdownColumnFields()
      this.setTableWidth()
    },

    handleActiveColumns(event) {
      event.value.sort((a, b) => {
        return this.defaultColumns.findIndex(f => f.field === a.field) -
          this.defaultColumns.findIndex(f => f.field === b.field)
      })
      this.activeColumns = event.value
      this.setFrozenColumns()

      this.setTableWidth()
    },

    setTableWidth() {
      this.$nextTick(() => {
        if (this.activeColumns) {
          const widths = this.activeColumns.map(field => field.width)
          this.tableWidth = widths.reduce((total, width) => Number(total) + Number(width), 0)

        }
      })
    },

    handleInput(event) {
      if (event.length < 3 && event) return
      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.filters.global.value = event.trim()
      }, 300)
    },

    highlightSearchTerm(data, key) {
      const filterKey = `${key}.value`
      const hasFilterValue = this.filters[filterKey]?.constraints.some(c => c.value?.length)
      const isFilterDropdown = this.activeColumns.some(column => column.field === key && column.matchMode)

      let search = this.filters.global.value

      const match = (search && data && !isFilterDropdown && !this.excludeFromGlobalSearch.includes(key))
        ? String(data).match(new RegExp(search, 'i'))
        : null

      if (match) {
        search = String(data).replace(match[0],
          `<span class="hl-search">${match[0]}</span>`,
        )
        if (!hasFilterValue) {
          return search
        }
      } else {
        search = ''
      }

      if (hasFilterValue) {
        this.filters[filterKey]?.constraints.map(constraint => {
          const terms = data.split(', ')
          const patternMatch = isFilterDropdown && constraint.value.includes(data)
            ? [data]
            : String(data).match(new RegExp(constraint.value, 'i')) || []

          if (!patternMatch.length && isFilterDropdown && terms.length > 1) {
            // Dropdown with comma-separated values matching (e.g. Tags)
            terms.forEach(term => {
              if (constraint.value.includes(term)) {
                patternMatch.push(term)
              }
            })

            patternMatch.forEach(p => {
              data = data.replace(p, `<span class="hl-filter">${p}</span>`)
            })

            search = data
          } else {
            search = String(search || data).replace(patternMatch[0],
              `<span class="hl-filter">${patternMatch[0]}</span>`,
            )
          }

        })
        return search || data
      }

      return data
    },

    setTableFilters() {
      this.activeColumns.map(column => {
        if (column.filterDisabled) return

        const field = `${column.field}.value`
        this.filters[field] = {
          operator: FilterOperator.OR,
          constraints: [{
            value: null,
            matchMode: column.matchMode || // commaSeparated values => different mode
              (column.options ? 'filterByArrayEquals' : FilterMatchMode.CONTAINS)
          }],
        }
      })
    },

    clearAllFilters() {
      this.filters.global.value = ''

      // Reset all main filters
      this.setStatusFiltersCounts()

      Object.keys(this.filters).map(filter => {
        if (this.filters[filter].constraints) {
          this.filters[filter].constraints.map(constraint => constraint.value = null)
        }
      })

      this.setTableFilters()
    },

    resetTableSettings() {
      this.setDefaultRealisationFields()
      this.tableKey++
      this.setTableWidth()
      this.setTableFilters()
      this.setFrozenColumns()
    },

    handlePagination(event) {
      if ((this.page === 1 && event.page === 0) && this.perPage === event.rows) return

      this.page = this.realisationsStorage.page = this.perPage !== event.rows ? 1 : event.page + 1
      this.perPage = this.realisationsStorage.perPage = event.rows

      localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))

      this.$router.replace({
        query:
        {
          ...(event.page > 0 ? { p: event.page + 1 } : {}),
          ...(event.rows > 10 ? { pp: event.rows } : {}),
        },
      })
    },

    handleColumnSort(event) {
      if (this.sortMode === 'multiple') {
        // With sortMode multiple, removable sort cannot be properly done with this version
        // multiSortMeta updates only current column and if removable sort is selected, it gives back 'undefined'
        // witch makes it impossible to remove only that column. It resets sorting on all columns!
        const [sortField] = event.multiSortMeta
        const sortFieldIndex = this.multiSortMeta.findIndex(f => f.field === sortField?.field)

        if (sortFieldIndex !== -1) {
          this.multiSortMeta.splice(sortFieldIndex, 1)
        }

        if (sortField) {
          this.multiSortMeta.push(sortField)
        } else {
          this.multiSortMeta = []
        }

        this.realisationsStorage.multiSortMeta = this.multiSortMeta
      } else {
        this.realisationsStorage.sortField = this.sortField = event.sortField
        this.realisationsStorage.sortOrder = this.sortOrder = event.sortOrder
      }

      localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
    },

    removeActiveMenuFilter() {
      // PrimeVue dataTable column filter type menu overlay bug: on route change, it's not destroyed and stays visible //
      // Column template filter append filter menu to body instead of render within a header component //
      const filterMenu = document.querySelector('.p-column-filter-overlay')
      if (filterMenu) {
        filterMenu.remove()
      }
    },

    filterLabel({ filter }) {
      return this.statusFilters.filters[filter].label.replace(' ', '').toLowerCase()
    },

    replaceRoute({ filter }) {
      this.selectedStatusFilter = filter
      const type = this.$route.params.type || 'realisatie'

      this.$router.replace({
        ...(filter === 'all'
          ? { name: 'ProcessList', params: { type } }
          : { params: { type, filter: this.filterLabel({ filter }) } }
        ),
        query: {
          ...(this.page > 1 ? { p: this.page } : {}),
          ...(this.perPage > 10 ? { pp: this.perPage } : {}),
        },
      }).catch(() => { })
    },

    handleFilterProcesses({ filter }) {
      if (this.selectedStatusFilter === filter) return

      this.isDataLoading = true
      this.page = 1
      this.realisationsStorage.page = this.page
      this.replaceRoute({ filter })

      setTimeout(() => {
        this.realisations = this.records
          .filter(record => record.status.value[filter])
          .sort((a, b) => b.updated_at_nr.value - a.updated_at_nr.value)

        this.realisationsStorage.selectedStatusFilter = this.selectedStatusFilter
        localStorage.setItem('realisationList', JSON.stringify(this.realisationsStorage))
        this.isDataLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-search {
  position: sticky;
  top: 0;
  z-index: 1;

  .p-inputtext {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  ::v-deep .p-chip.p-component {
    &.chip-small {
      background-color: #777;
      color: white;
      border-radius: 4px;

      .p-chip-text {
        font-size: 11px;
      }
    }
  }

  .filter-chips {
    background: white;
    gap: 0.3em;
    flex-flow: row wrap;
  }
}

.ItemList {
  margin: 1rem 1rem 0;

  .realisations {
    border: none;

    .realisations-table {
      width: 100% !important;
    }
  }

  .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.4vh 0.5rem;
  }
}

.gap-1 {
  gap: 1em;
}

.selectable-fields {
  min-width: 350px;

  .p-multiselect-item {
    &.p-highlight {
      background: none !important;
    }
  }
}

.p-component {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.p-datatable-wrapper {
  padding-bottom: 1em !important;
}

::v-deep .p-frozen-column {
  z-index: 1;
}

::v-deep .p-paginator .p-dropdown {
  display: flex;
  align-items: center;
}

.pi-link {
  position: relative;
  top: -2px;
}

.p-multiselect-header {
  &:after {
    content: 'Selecteer / deselecteer alles';
    position: absolute;
    left: 2.7em;
    pointer-events: none;
  }

  .p-checkbox {
    flex: 1;
  }
}

.column {
  .p-multiselect-header {
    &:after {
      display: none;
    }
  }
}

.freeze {
  order: 1;
  color: #495057 !important;

  &.pi-lock {
    color: #2196F3 !important;
  }
}

::v-deep .p-column-header-content {
  flex: 1;
}

.p-button {
  &.p-button-icon-only {
    width: auto;
    min-width: 2.5em;
  }
}

.p-skeleton {
  margin: 0.25em 0;
}

.fa.fa-refresh {
  font-size: 20px;
  color: #999;
}
</style>
