import store from '@/store'
import Process from '@/models/Process';
import Realisation from '@/models/Realisation';
import ProcessesConfig from '@/components/Table/configs/ProcessesConfig';
import { realisationListFields } from '@/config';

let mappedProcesses = []
const counts = {
  current: 0,
  total: 0
}

export function mapProcesses ({ processType, processes }) {
  const isRealisationType = processType === 'realisation'
  const ProcessType = isRealisationType ? Realisation : Process

  const records = processes.map(process => {
    return new ProcessType({ data: process.data, ref: process.ref['@ref'].id })
  })

  if (isRealisationType) {
    store.dispatch('realisations/setRecords', { records })
  }
  const mappedRecords = records.map(ProcessesConfig.dataMapper).slice();
  const formattedRecords = mappedRecords.map(record => realisationListFields({ record }))
  mappedProcesses = [...mappedProcesses, ...formattedRecords]

  const storeType = isRealisationType
    ? 'realisations/setRealisationTableRecords'
    : 'processes/setProcessTableRecords'

  store.dispatch(storeType, {
    records: mappedProcesses,
    processType
  });

  return formattedRecords
}

export function clearTempProcesses () {
  mappedProcesses = []
}

export async function fetchRealisationProcess ({ token, locationId, processType }) {
  const api = await fetch('/api/process', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      locationId
    })
  }).catch(err => console.log(err))

  if (!api.ok) {
    throw new Error('Getting realisation failed! ')
  }

  const response = await api.json()

  const process = response.data.record
  process.data.realisationProcessData = JSON.parse(JSON.stringify(process.data))
  process.data.realisationProcessData.ref = process.ref['@ref'].id
  process.data.realisationCategory = process.data.category
  process.data.processType = processType

  const realisationProcessData = process.data.realisationProcessData

  const [rpLocation] = realisationProcessData.Locations

  const data = {
    processType: process.data.processType,
    realisationProcessData,
    realisationCategory: process.data.category,
    uuid: '',
    MunicipalityCode: realisationProcessData.MunicipalityCode,
    Locations: [{
      Municipality: realisationProcessData.MunicipalityCode,
      ChargePointOperator: rpLocation.ChargePointOperator,
      GridOperator: rpLocation.GridOperator,
      Contractor: rpLocation.Contractor
    }],
    locationId: realisationProcessData.locationId
  }

  store.dispatch('processes/setSelectedProcess', {
    process: new Process({ data, ref: null, newProcess: true })
  })
}

export async function fetchAssetProcess ({ token, uuid }) {
  const api = await fetch('/api/process', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      uuid
    })
  }).catch(err => console.log(err))

  if (!api.ok) {
    throw new Error('Getting process failed! ')
  }

  const response = await api.json()
  const process = response.data.record

  store.dispatch('processes/setSelectedProcess', {
    process: new Process({ data: process.data, ref: process.ref['@ref'].id })
  })

  return process
}

export async function fetchProcessesByType ({ token, processType, code, after }) {

  const api = await fetch('/api/processlist', { // TODO: fix this...was processlist, but that endpoint is not filtering on CPO
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      processType, code, after
    })
  }).catch(err => console.log(err))

  if (!api.ok) {
    throw new Error(`Getting ${processType} processes failed!`)
  }

  const response = await api.json()


  counts.total = response.data.count
  counts.current += response.data.records.length
  store.dispatch('processes/setCounts', { counts });
  mapProcesses({ processType, processes: response.data.records })

  if (response.data.after) {
    return await fetchProcessesByType({ token, processType, code, after: response.data.after })
  }

  counts.total = 0
  counts.current = 0

  if (!code) {
    mappedProcesses = []
  }

  return mappedProcesses
}

export async function fetchAssetByLocationId ({ token, locationId }) {
  const api = await fetch('/api/assets', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      locationId
    })
  })
  if (!api.ok) {
    throw new Error('failed')
  }

  const response = await api.json()

  store.dispatch('processes/setSelectedAsset', { asset: response.data.assets })
  return response.data
}
